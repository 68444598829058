import React from "react"
import { Link } from "gatsby";

import Layout from "../containers/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Thank you page" />
    <div className="fourofour">
      <div className="fourofour__content">
        <h1>Bedankt voor uw bestelling!</h1>
        <p>In de mail vind u meer informatie over uw bestelling, we zullen zo snel mogelijk contact opnemen</p>
        <Link to="/">
          <button className={'button'}>Terug naar homepage</button>
        </Link>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
